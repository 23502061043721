<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <div class="mechantPage">
         <section class="blockElement">
            <div class="container">
               <div class="text-center">
                  <h1 class="mb-0">{{contentlist.merchant_tool_plugin_content1}} |
                    <span class="typed-text primarycolor">Woocommerce</span>
                    <!-- <span class="cursor">&nbsp;</span> -->

                  </h1>
                  <p class="d-block mt-4">{{contentlist.merchant_tool_plugin_content3}} <br> {{contentlist.merchant_tool_plugin_content4}}</p>
               </div>
               <div class="mt-5 pt-2 pt-md-3 pt-xl-5">
                  <div class="d-block d-md-flex justify-content-center">

                     <router-link :to="entityPath+'/merchant-tools-woocommerce'"  class="d-flex justify-content-center align-items-center shadow-md rounded-pill bg-white mechantLogos mx-3 mx-xl-4">
                        
                        <img src="/assets/images/woocommerce-logo.webp" alt="woocommerce logo" title="woocommerce logo" class="img-fluid m-auto" />
                     <h2 class="text-white mb-0">{{contentlist.get_started_content}}</h2>
                     
                     </router-link>

                     <!-- <a href="javascript:void(0);" class="d-flex justify-content-center align-items-center shadow-md rounded-pill bg-white mechantLogos mx-3 mx-xl-4">
                     <img src="/assets/images/shopify-logo.webp" alt="shopify logo" title="shopify logo" class="img-fluid m-auto" />
                     <h2 class="text-white mb-0">{{contentlist.get_started_content}}</h2>
                     </a>
                     <a href="javascript:void(0);" class="d-flex justify-content-center align-items-center shadow-md rounded-pill bg-white mechantLogos mx-3 mx-xl-4">
                     <img src="/assets/images/magento-logo.webp" alt="magento logo" title="magento logo" class="img-fluid m-auto" />
                     <h2 class="text-white mb-0">{{contentlist.get_started_content}}</h2>
                     </a> -->
                  </div>
                  <!-- <div class="d-block d-md-flex justify-content-center">
                     <a href="javascript:void(0);" class="d-flex justify-content-center align-items-center shadow-md rounded-pill bg-white mechantLogos mx-3 mx-xl-4">
                     <img src="/assets/images/bigcommerce-logo.webp" alt="bigcommerce logo" title="bigcommerce logo" class="img-fluid m-auto" />
                     <h2 class="text-white mb-0">{{contentlist.get_started_content}}</h2>
                     </a>
                     <a href="javascript:void(0);" class="d-flex justify-content-center align-items-center shadow-md rounded-pill bg-white mechantLogos mx-3 mx-xl-4">
                     <img src="/assets/images/opencart-logo.webp" alt="opencart logo" title="opencart logo" class="img-fluid m-auto" />
                     <h2 class="text-white mb-0">{{contentlist.get_started_content}}</h2>
                     </a>
                  </div>
                  <div class="d-block d-md-flex  justify-content-center">
                     <a href="javascript:void(0);" class="d-flex justify-content-center align-items-center shadow-md rounded-pill bg-white mechantLogos mx-3 mx-xl-4">
                     <img src="/assets/images/prestashop-logo.webp" alt="prestashop logo" title="prestashop logo" class="img-fluid m-auto" />
                     <h2 class="text-white mb-0">{{contentlist.get_started_content}}</h2>
                     </a>
                  </div> -->
               </div>
            </div>
         </section>
         <section class="blockElement pt-0">
            <div class="container">
               <div class="row justify-content-center">
                  <div class="col-12 col-md-10 col-lg-7 text-center">
                     <h2 class="mb-0">{{contentlist.merchant_tool_plugin_content6}}</h2>
                     <p class="d-block mt-4">{{contentlist.merchant_tool_plugin_content7}}</p>
                     
                     <div class="d-block text-center mt-5">
                        <router-link :to="entityPath+'/contact-us'" class="themeBtn medium">{{contentlist.contact_us_content}}</router-link>
                     </div>
                  </div>
               </div>
               <div class="text-center">
               </div>
            </div>
         </section>
      </div>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                     <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                     <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
   import { commonAllmixins } from '@/plugins/commonAll'  
   export default ({
      mixins: [ commonAllmixins],  
      mounted: function() {
        //let arraydata = ["Woocommerce"];
        //this.typeTextFunc(arraydata)
      }
   });
</script>
